[class$="btn-main"] {
  box-shadow: 0px 2px 1px 0px #00000029;
  border-radius: 4px;
  font-weight: 500;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  &:disabled {
    background: #2b3034 !important;
    color: #545c64;
  }
}
.blue-btn-main {
  background: #2c9aff;
  &:hover {
    background: #0e7ade;
  }
  &:active {
    background: #2b7bff;
  }
}
.red-btn-main {
  background: #f06262;
  &:hover {
    background: #e54646;
  }
  &:active {
    background: #f06262;
  }
}
.gray-btn-main {
  background: #495057;
  width: 80px;
  height: 27px;
  font-size: 18px;
  border-radius: 4px;
  &:hover {
    background: #868e96;
  }
  &:active {
    background: #545c64;
  }
}
.border-btn-main {
  border: 1px solid #545c64;
  background: rgba(0, 0, 0, 0);
  color: #adb5bd;
  &:hover {
    border: 1px solid #adb5bd;
    color: #ced4da;
  }
  &:active {
    color: #ced4da;
    border: 1px solid #868e96;
  }
}
.control-btn {
  width: 39px;
  height: 39px;
}
