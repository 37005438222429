/*!
 *  Iconly icon font. Generated by Iconly: https://iconly.io/
 */

@font-face {
  font-display: auto;
  font-family: "Iconly";
  font-style: normal;
  font-weight: 400;
  src: url("./iconly.eot?1701757167329");
  src: url("./iconly.eot?#iefix") format("embedded-opentype"), url("./iconly.woff2?1701757167329") format("woff2"), url("./iconly.woff?1701757167329") format("woff"), url("./iconly.ttf?1701757167329") format("truetype"), url("./iconly.svg?1701757167329#Iconly") format("svg");
}

[class="icon"], [class^="icon-"], [class*=" icon-"] {
  display: inline-block;
  font-family: "Iconly" !important;
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.icon-icon_service:before {
  content: "\e000";
}

.icon-icon_monitoring:before {
  content: "\e001";
}

.icon-icon_mgmtapp:before {
  content: "\e002";
}

.icon-icon_dashboard:before {
  content: "\e003";
}

.icon-icon_vim:before {
  content: "\e004";
}

.icon-icon_ocastration:before {
  content: "\e005";
}

.icon-icon_user:before {
  content: "\e006";
}

.icon-icon_myinfo:before {
  content: "\e007";
}

.icon-icon_logout:before {
  content: "\e008";
}

.icon-icon_sheild:before {
  content: "\e009";
}

.icon-icon_cluster:before {
  content: "\e00a";
}

.icon-icon_open_pop:before {
  content: "\e00b";
}

.icon-icon_stop:before {
  content: "\e00c";
}

.icon-icon_run:before {
  content: "\e00d";
}

.icon-icon_refresh:before {
  content: "\e00e";
}

.icon-icon_app:before {
  content: "\e00f";
}

.icon-icon_cluster_stack:before {
  content: "\e010";
}

.icon-icon_drag_handle:before {
  content: "\e011";
}
