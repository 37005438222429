.map-sec {
  min-width: 417px;
  width: 417px;
  height: 100%;
  max-height: 587px;
  background-image: url(../../assets/images/map.svg);
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  .local {
    color: #adb5bd;
    font-size: 18px;
    font-weight: 600;
    position: absolute;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    .cluster-items-wrap {
      width: max-content;
      position: absolute;
      bottom: 42px;
      left: 50%;
      transform: translateX(-50%);
      padding: 2px;
      box-shadow: 0px 4px 6px 0px #0000003d;
      background: #2c9aff;
      border-radius: 4px;
      gap: 2px;
      z-index: 100;
      &::after {
        position: absolute;
        content: "";
        bottom: 0;
        left: 50%;
        width: 0;
        height: 0;
        border: 5px solid transparent;
        border-top-color: #2c9aff;
        border-bottom: 0;
        margin-left: -5px;
        margin-bottom: -5px;
      }
      .cluster-item {
        border-radius: 4px;
        min-width: 110px;
        height: 40px;
        width: 100%;
        color: #fff;
        justify-content: flex-start;
        padding: 0 10px;
        background: #17181ab2;
        &:hover {
          background: #17181ad9;
        }
        &.active {
          background: #17181a;
        }
        em {
          margin-right: 5px;
        }
        p {
          word-break: keep-all;
          font-size: 28px;
          font-weight: 400;
        }
      }
    }
    &:hover {
      color: #fff;
      font-size: 20px;
    }
    p {
      z-index: 99;
    }
    &.active {
      color: #fff;
      font-size: 20px;
      &::before {
        z-index: 97;
        position: absolute;

        content: url(../../assets/images/map_active.svg);
      }
    }
    &.sl {
      left: 95px;
      top: 115px;
    }
    &.gk {
      left: 125px;
      top: 138px;
    }
    &.ic {
      left: 58px;
      top: 138px;
    }
    &.gw {
      right: 160px;
      top: 100px;
    }
    &.cb {
      left: 158px;
      top: 190px;
    }
    &.sj {
      left: 118px;
      top: 225px;
    }
    &.cn {
      left: 80px;
      top: 244px;
    }
    &.dj {
      left: 135px;
      top: 260px;
    }
    &.kb {
      right: 130px;
      top: 235px;
    }
    &.dg {
      right: 135px;
      top: 315px;
    }
    &.jb {
      left: 110px;
      bottom: 250px;
    }
    &.kn {
      right: 170px;
      bottom: 200px;
    }
    &.bs {
      right: 95px;
      bottom: 185px;
    }
    &.us {
      right: 75px;
      bottom: 225px;
    }
    &.gj {
      left: 75px;
      bottom: 175px;
    }
    &.jn {
      left: 110px;
      bottom: 150px;
    }
    &.jj {
      left: 48px;
      bottom: 35px;
    }
  }
}
