.user-dash-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  .user-left {
    width: 33.33333333%;
    max-width: 33.33333333%;
    height: 100%;
    gap: 24px;
    .my-allocation {
      width: 100%;
      min-height: 160px;
      .box-contents {
        .user-my-info-item {
          width: 33.3333333%;
          height: 100%;
          align-items: center;
          gap: 8px;
          .label {
            color: #868e96;
            font-size: 28px;
            font-weight: 500;
            span {
              font-size: 18px;
            }
          }
          .value {
            color: #fff;
            font-size: 48px;
            font-weight: 700;
            line-height: 1.5rem;
            span {
              font-size: 32px;
            }
          }
        }
        padding: 0 30px;
      }
    }
    .mec-app {
      width: 100%;
      height: calc(100% - 100px);
      .box-contents {
        .user-mec-app {
          width: 100%;
          height: 100%;
          gap: 25px;
          .top {
            width: 100%;
            height: 100px;
            gap: 15px;
            padding: 10px 15px;
            .user-mec-item {
              width: 25%;
              height: 100%;
              align-items: center;
              gap: 8px;
              border-right: 1px solid #343a40;
              &:last-child {
                border: none;
              }
              .label {
                color: #868e96;
                font-size: 28px;
                font-weight: 500;
              }
              .value {
                color: #fff;
                font-size: 48px;
                font-weight: 700;
              }
            }
          }
          .bot {
            width: 100%;
            height: calc(100% - 100px);
          }
        }
      }
    }
  }
  .user-center {
    width: 98%;
    height: 50%;
    gap: 24px;
    .sm-monitoring {
      width: 100%;
      height: 100%;
      .cluster-usage-wrap {
        width: 100%;
        padding: 5px 0;
        zoom: 0.9;
        overflow: hidden;
        justify-content: space-between;
      }
    }
    .mec-service {
      width: 100%;
      height: 96%;
      .box-contents {
        display: flex;
        flex-direction: column;
        .service-wrap {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          &::-webkit-scrollbar {
            width: 2px;
          }
          &::-webkit-scrollbar-thumb {
            height: 30%;
            background: #545c64;
            border-radius: 10px;
          }
          &::-webkit-scrollbar-track {
            background: #17181a;
          }
        }
        .service-info-wrap {
          width: 100%;
          .mec-item-info {
            width: 33.333333%;
            .mec-item-label {
              height: 25px;
              line-height: 25px;
              color: #868e96;
              font-size: 28px;
              font-weight: 500;
              text-align: center;
            }
            .mec-item-value {
              font-size: 48px;
              font-weight: 700;
              text-align: center;
            }
          }
        }
        .line {
          margin: 5px 0;
          min-height: 1px;
        }
        .top5-service-wrap {
          margin-bottom: 10px;
          width: 100%;
          height: 100%;
          .title {
            font-size: 28px;
            color:#868e96;
            font-weight: 500;
            width: 100%;
            text-align: center;
            margin-bottom: 10px;
          }
          .mec-bar-wrap {
            margin-left:20px;
            width: 90%;
            gap: 10px;
            height: calc(100% - 52px);
            justify-content: space-between;
            .mec-bar-item {
              width: 100%;
              height: 100%;
              justify-content: space-between;
              .bar {
                padding-left: 17px;
                text-align: left;
                width: 90%;
                border-radius: 1000px;
                height: 100%;
                background: #2b3034;
                overflow: hidden;
                position: relative;
                .fill {
                  position: absolute;
                  height: 100%;
                  left: 0;
                  top: 0;
                  z-index: 98;
                  background: #2c9aff;
                }
                .fill-green {
                  position: absolute;
                  height: 100%;
                  left: 0;
                  top: 0;
                  z-index: 98;
                  background: #44b94b;
                }
                p {
                  z-index: 99;
                  font-size: 28px;
                  font-weight: 500;
                }
              }
              p {
                font-size: 32px;
                font-weight: 500;
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
  }
  .user-right {
    width: 98%;
    height: 50%;
    .monitoring {
      height: 100%;
      .user-mon-wrap {
        width: 100%;
        height: 100%;
        gap: 10px;
        .user-mon-chart {
          width: 100%;
          height: 50%;
        }
      }
    }
  }
}
