.monitoring-wrap {
  width: 100%;
  height: 100%;
  gap: 16px;
  justify-content: flex-start;
  .iframe-wrap {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    box-shadow: 0px 3px 2px 0px #00000033;
    border: 1px solid #343a40;
    background: #00000080;
  }
}
