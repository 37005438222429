.log-wrap {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  border: 1px solid #343a40;
  background: #191c1fb2;
  .log-line {
    height: 25px;
    .line-number {
      background: #343a40;
      width: 28px;
      height: 25px;
      padding: 0 3px;
      justify-content: flex-start;
      p {
        font-size: 20px;
        font-weight: 400;
        color: #868e96;
      }
    }
    .log-content {
      font-family: "Courier";
      padding: 0 3px;
      p {
        font-size: 20px;
        font-weight: 400;
        color: #fff;
      }
    }
  }
}
