.custom-select-wrap {
  width: 100%;
  height: 100%;
  background: #212529;
  border: 1px solid #545c64;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 2px 1px 0px #00000029;
  padding: 0px 8px;
  color: #868e96;
  font-size: 14px;
  font-weight: 300;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &.active {
    // border: 1px solid #5e9eff;
    // box-shadow: 0px 0px 6px 0px #5e64ff66;
    // background: #f7faff;
  }
  .selected-value {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &.placeholder {
      color: #868e96;
    }
  }
  .custom-select-option {
    position: absolute;
    width: 100%;
    background: #212529;
    border: 1px solid #545c64;
    border-radius: 4px;
    left: 0;
    top: 40px;
    z-index: 999;
    .custom-option {
      cursor: pointer;
      height: 30px;
      padding: 0px 8px;
      display: flex;
      align-items: center;
      background: #212529;
      justify-content: flex-start;
      border-bottom: 1px solid #545c64;
      p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      &:last-child {
        border: none;
      }
      &:hover {
        background: #2e3338;
      }
    }
  }
}
