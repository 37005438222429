.dash-detail-wrap {
  width: 100%;
  height: 100%;
  gap: 24px;
  justify-content: space-between;
  .detail-contents {
    gap: 24px;
    & > div {
      height: 100%;
    }
    .left-sec,
    .right-sec {
      flex: 1;
    }
    .left-sec {
      width: 100%;
      gap: 24px;
      .mec-app-wrap {
        width: 100%;
        height: 160px;
        .box-contents {
          padding: 15px;
          .mec-item {
            width: 25%;
            height: 100%;
            align-items: flex-start;
            border-left: 1px solid #343a40;
            padding-left: 7px;
            gap: 7px;
            &:first-child {
              border: none;
              padding-left: 0;
            }
            em {
              padding: 6px;
              background: #2b3034;
              border-radius: 4px;
            }
            .mec-item-info {
              align-items: flex-start;
              width: 100%;
              margin-right: 7px;
              .mec-item-label {
                align-self: center;
                height: 28px;
                line-height: 28px;
                color: #868e96;
                font-size: 25px;
                font-weight: 500;
                text-align: left;
              }
              .mec-item-value {
                align-self: center;
                font-size: 36px;
                font-weight: 700;
                text-align: left;
              }
            }
          }
        }
      }
      .cluster-monitoring {
        width: 100%;
        height: calc(100% - 140px);
        .box-contents {
          .cluster-info {
            width: 100%;
            height: 100%;
            padding: 10px;
            .cluster-other-chart {
              margin-top: 15px;
              width: 100%;
              height: 30.5%;
              overflow: hidden;
              .cluster-bandwidth {
                width: 50%;
                height: 100%;
              }
              .cluster-packet {
                width: 50%;
                height: 100%;
              }
              .cluster-bandpacket {
                width: 100%;
                height: 100%;
              }
            }
            .cluster-disk-io {
              width: 100%;
              height: 34.5%;
              margin-top: 15px;
              overflow: hidden;
            }
            .cluster-usage-wrap {
              padding: 5px 0;
              zoom: 0.8;
              overflow: hidden;
              height: 25%;
              justify-content: space-between;
              border-bottom: 1px solid #495057;
            }
          }
        }
      }
    }
    .center-sec {
      width: 496px;
      flex-shrink: 0;
      min-width: 496px;
      .stack-control-wrap {
        width: calc(100% - 400px);
        height: 100%;
        gap: 7px;
        .move-stack {
          border: 1px solid #fff;
          width: 38px;
          height: 38px;
          border-radius: 4px;
          &.disabled {
            pointer-events: none;
            border: 1px solid #343a40;
          }
        }
      }
      .stack-wrap {
        width: 400px;
        max-width: 400px;
        height: 100%;
        border: 8px solid #343a40;
        border-radius: 10px;
        justify-content: flex-end;
        padding: 10px;
        gap: 5px;
        overflow: hidden;
        .stack-top {
          width: 360px;
          height: 12px;
          background-image: url(../../../../assets/images/server_rac_top.svg);
          background-position: center;
          background-repeat: no-repeat;
          background-size: auto;
        }
        .stack-item {
          width: 360px;
          height: 80px;
          min-height: 80px;
          background-image: url(../../../../assets/images/server_rac_normal.svg);
          background-position: center;
          background-repeat: no-repeat;
          background-size: auto;
          padding-left: 60px;
          cursor: pointer;
          .stack-info {
            align-items: flex-start;
            color: #adb5bd;
            font-size: 16px;
            font-weight: 400;
            gap: 2px;
            .stack-name {
              font-size: 17px;
              font-weight: 500;
            }
          }
          &:hover {
            background-image: url(../../../../assets/images/server_rac_hover.svg);
            .stack-info {
              color: #f8fafc;
            }
          }
          &.active {
            background-image: url(../../../../assets/images/server_rac_active.svg);
            .stack-info {
              color: #f8fafc;
            }
          }
        }
      }
    }
    .right-sec {
      width: 100%;
      .box-contents {
        justify-content: space-between;
        .pod-info-wrap {
          width: 100%;
          align-items: flex-start;
          .title {
            font-weight: 500;
            font-size: 28px;
            margin-bottom: 5px;
          }
          .pod-info-table-wrap {
            width: 100%;
          }
        }
        .node-monitoring {
          width: 100%;
          margin-top: 8px;
          overflow: hidden;
          .title {
            align-self: flex-start;
            font-weight: 500;
            font-size: 28px;
            margin-bottom: 5px;
          }
        }
        .cluster-disk-io {
          width: 100%;
          height: 18%;
          margin-top: 8px;
          overflow: hidden;
        }
        .cluster-bar-io {
          width: 100%;
          height: 18%;
          margin-top: 8px;
          overflow: hidden;
        }
      }
    }
  }
}
