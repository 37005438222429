.gauge-wrap {
  .gauge-label {
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 5px;
  }
  .gauge-container {
    position: relative;
    .flexRow {
      position: absolute;
      font-size: 30px;
      font-weight: 700;
      left: 50%;
      bottom: 38px;
      transform: translateX(-50%);
      span {
        font-size: 28px;
      }
    }
  }
}
