/*테이블 css*/
.MuiTooltip-popper {
  .MuiTooltip-tooltip {
    padding: 10px 12px !important;
    background: #17181a !important;
    color: #fff !important;
    font-size: 13px !important;
    font-weight: 400 !important;
    border-radius: 4px !important;
  }
}

.custom-table {
  background: #212529;
  box-shadow: 2px 2px 4px 0px #10203729;
  & > div {
    & > div {
      background-color: #212529 !important;
      & > div {
        background-color: #212529 !important;
        color: #ced4da !important;
      }
    }
  }
  .rdt_Table {
    .rdt_TableHead {
      min-height: 45px;
      .rdt_TableHeadRow {
        color: #adb5bd;
        font-size: 21px;
        font-weight: 900;
        min-height: 45px;
        background: #212529;
        & > div {
          display: flex;
          justify-content: center;
        }
      }
    }
    .rdt_TableBody {
      .rdt_TableRow {
        font-size: 24px;
        font-weight: 500;
        color: #ced4da;
        background: #fff;
        min-height: 45px;
        border-bottom: 1px solid #343a40;
        .rdt_TableCell {
          font-weight: 300;
          color: #ced4da;
        }
        &:hover {
          outline-color: rgba(0, 0, 0, 0) !important;
          background-color: #0e7ade33;
        }
        &:nth-child(2n) {
          background-color: #212529;
          &:hover {
            background-color: #0e7ade33;
          }
        }
        &:nth-child(2n + 1) {
          background-color: #2b3034;
          &:hover {
            background-color: #0e7ade33;
          }
        }
      }
    }
  }
}

.custom-table-wrap {
  border: 1px solid #343a40;
  width: 100%;
  background: #212529;
  border-radius: 6px !important;
  padding-bottom: 10px;
  & > div {
    border-radius: 0px !important;
  }
  header {
    padding: 0;
    background-color: rgba(0, 0, 0, 0) !important;
  }
  .table-info {
    width: 100%;
    justify-content: space-between;
    margin-bottom: 10px;
    p {
      color: #757d85;
      font-size: 24px;
      font-weight: 500;
      span {
        font-weight: 600;
        color: #292f35;
      }
    }
  }
}
.pagination-container {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 25px;
  .custom-select-wrapper-perpage {
    margin-left: 15px;
    height: 28px;
    width: 70px;
  }
  p.total-page-text {
    color: #9199a1;
    font-size: 12px;
    font-weight: 400;
    margin-right: 15px;
  }
  button.btn-pagination {
    cursor: pointer;
    border: none;
    width: 28px;
    height: 28px;
    font-size: 13px;
    font-weight: 500;
    color: #adb5bd;
    background-color: rgba(0, 0, 0, 0) !important;
    &.active-btn {
      background: #00a2ed1a !important;
      color: #2c9aff;
    }
    svg {
      vertical-align: -2px;
    }
  }
}
.custom-pagination {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
//테이블 내부 요소들
.table-go-detail-btn {
  justify-content: space-between;
  padding: 0 10px;
  box-shadow: 0px 2px 1px 0px #00000029;
  background: #495057;
  width: 65px;
  height: 25px;
  border-radius: 4px;
  color: #e9ecef;
  font-size: 16px;
  font-weight: 500;
  p {
  }
  &:hover {
    background: #868e96;
  }
  &:active {
    background: #868e96;
  }
}
.status-chip {
  background: #adb5bd14;
  width: 92px;
  height: 26px;
  font-weight: 500;
  font-size: 18px;
  color: #adb5bd;
  border-radius: 11px;
  justify-content: center;
  cursor: default;
}
.is_running {
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
}
.agree-yn {
  gap: 8px;
  button {
    box-shadow: 0px 2px 1px 0px #00000029;
    border: 1px solid #545c64;
    background: rgba($color: #000000, $alpha: 0);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 39px;
    height: 39px;
    border-radius: 4px;
    svg {
      font-size: 8px;
      color: #adb5bd;
    }
    &:hover {
      border: 1px solid #adb5bd;
      color: #adb5bd;
    }
  }
}
.agree-users {
  cursor: pointer;
  text-decoration: underline;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.table-no-data-wrap {
  width: 100%;
  height: 100px;
  p {
    color: #868e96;
    font-weight: 500;
    font-size: 14px;
    margin-top: 5px;
  }
}
