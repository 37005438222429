.multi-gauge-wrap {
  width: 100%;
  height: 140px;
  justify-content: space-between;
  .label-container {
    width: 50%;
    .label-wrap {
      width: 80%;
      .label {
        justify-content: space-between;
        width: 100%;
        font-size: 22px;
        font-weight: 400;
        color: #fff;
        .name {
          display: flex;
          flex-direction: row;
          align-items: center;
          position: relative;
          &::before {
            left: -12px;
            content: "";
            position: absolute;
            width: 8px;
            height: 8px;
            background: #fff;
            border-radius: 1px;
          }
          &.name1 {
            &::before {
              background: #69db7c;
            }
          }
          &.name2 {
            margin: 7px 0;
            &::before {
              background: #ffd43b;
            }
          }
          &.name3 {
            &::before {
              background: #3bc9db;
            }
          }
        }
        .per {
          font-size: 32px;
          font-weight: 700;
        }
      }
    }
  }
  .gauge-container {
    width: 50%;
    height: 100%;
    svg {
      margin-top: -40px;
      zoom: 0.6;
    }
  }
}
