.main-header {
  border-bottom: 1px solid #343a40;
  background: #000000;
  width: 100%;
  height: 60px;
  justify-content: space-between;
  position: relative;
  padding: 0 19px 0 0px;
  .sub-menu-wrap {
    position: absolute;
    z-index: 997;
    top: -400px;
    left: 0;
    transition: 0.3s;
    padding: 10px 15px 30px 32px;
    box-shadow: 0px 4px 6px 0px #0000003d;
    background: #2b3034;
    border-radius: 0 0 40px 0;
    width: 360px;
    &.active-menu {
      top: 56px;
    }
    .sub-menu-item {
      cursor: pointer;
      color: #868e96;
      font-weight: 400;
      font-size: 25px;
      width: 100%;
      padding: 14px 16px;
      text-align: left;
      min-height: 46px;
      min-width: 196px;
      border-radius: 4px;
      font-weight: 700;
      em {
        margin-right: 13px;
      }
      &:hover {
        color: #fff;
        font-weight: 400;
      }
      &.active {
        background: linear-gradient(102.77deg, #3c7ddf 10.85%, #3351ec 92.37%);
        color: #fff;
        min-height: 50px;
        &:hover {
          color: #fff;
          font-weight: 700;
        }
      }
    }
  }
  .logo-sec {
    height: 100%;
    background: #000000;
    z-index: 999;
    padding-left: 22px;
    .hamburger-btn {
      padding: 10px;
      margin-right: 8px;
      border-radius: 4px;
      &:hover {
        background: rgba($color: #fff, $alpha: 0.16);
      }
      img {
        width: 36px;
      }
    }
    img {
      width: 300px;
    }
    .path-wrap {
      height: 30px;
      .ver-line {
        background: #e9ecef;
        width: 1px;
        height: 100%;
        margin: 0 10px 0 20px;
      }
      p {
        color: #e9ecef;
        font-size: 30px;
        margin-right: 10px;
        font-weight: 600;
      }
    }
  }
  .logo-sec-date {
    font-weight: 400;
    font-size: 28px;
    color: #c9ced4;
    svg {
      margin-right: 4px;
    }
  }
  .nav-sec {
    height: 17px;
    color: #ced4da;
    font-weight: 400;
    .ver-line {
      width: 1px;
      height: 100%;
      background: #545c64;
      margin: 0 15px;
    }
    .user-id {
      color: #adb5bd;
      font-size: 26px;
      padding: 8px 13px;
      border-radius: 4px;
      position: relative;
      cursor: pointer;
      &:hover {
        .userinfo-hover {
          display: flex;
        }
        background: rgba($color: #fff, $alpha: 0.16);
      }
      .userinfo-detail {
        z-index: 1;
        padding: 14px;
        box-shadow: 0px 4px 6px 0px #0000003d;
        border: 1px solid #343a40;
        background: #212529;
        position: absolute;
        right: 0;
        top: 38px;
        min-width: 360px;
        max-width: 360px;
        border-radius: 4px;
        align-items: flex-start;
        text-align: left;
        .user-id-email-wrap {
          .user-info-id {
            font-weight: 600;
            font-size: 32px;
            margin: 5px 0;
            color: #ced4da;
            word-break: break-all;
          }
          .user-info-email {
            color: #868e96;
            font-weight: 400;
            font-size: 24px;
          }
        }
        .user-info-btn-wrap {
          width: 100%;
          align-items: flex-start;
          button {
            color: #ced4da;
            font-size: 26px;
            font-weight: 400;
            width: 100%;
            text-align: left;
            padding: 7px;
            border-radius: 4px;
            em {
              margin-right: 5px;
            }
            &:hover {
              background: rgba($color: #fff, $alpha: 0.12);
            }
          }
        }
        .user-type {
          font-size: 22px;
          font-weight: 400;
          margin: 5px 0;
          &.admin {
            color: #e54646;
          }
          &.user {
            color: #2b7bff;
          }
        }
        .hor-line {
          width: 100%;
          height: 1px;
          background: #495057;
          margin: 10px 0;
        }
      }
      .userinfo-hover {
        display: none;
        position: absolute;
        right: 0;
        top: 50px;
        max-width: 180px;
        color: #fff;
        padding: 5px 10px;
        background: #000000;
        border-radius: 4px;
        p {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 19px;
          font-weight: 500;
        }
      }
      svg {
        margin-right: 4px;
      }
    }
  }
}
//팝업
.popup-wrap {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  .popup-content {
    border-radius: 6px;
    width: 420px;

    box-shadow: 4px 4px 12px 0px #2725381f;
    border: 1px solid #dce1e5;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .popup-header {
      width: 100%;
      height: 54px;
      border-top: 2px solid #525a61;
      border-radius: 6px 6px 0 0;
      justify-content: space-between;
      padding: 0 15px;
      svg {
        cursor: pointer;
      }
      .popup-title {
        font-size: 16px;
        font-weight: 700;
        color: #292f35;
        svg {
          margin-right: 10px;
        }
      }
    }
    .popup-sec {
      padding: 10px 15px;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: space-between;
      .popup-sec-content {
        .select-box-wrap,
        .text-box-wrap {
          width: 100%;
          height: 36px;
          .custom-text-wrap {
            width: 100% !important;
          }
        }
      }
      .popup-normal-text {
        padding-left: 65px;
        text-align: left;
        width: 100%;
        font-weight: 400;
        font-size: 14px;
        color: #3a4148;
      }
    }
    .popup-sec-btn-wrap {
      margin-top: 20px;
      align-self: flex-end;
      gap: 10px;
      .main-btn {
        word-break: keep-all;
      }
    }
  }
}
.no-scroll {
  overflow: hidden !important;
}
.main-title {
  font-size: 24px;
  font-weight: 700;
  color: #292f35;
}
.sub-title {
  font-size: 14px;
  font-weight: 600;
  color: #3c6db6;
}
.download-button {
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 600;
  color: #757d85;
  background: #fff;
  border: 1px solid #c9ced4;
  border-radius: 4px;
  p {
    margin-bottom: 3px;
  }
}
.rdt_TableCell {
  div {
    word-break: keep-all;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.box-info {
  border: 1px solid #343a40;
  background: #212529;
  border-radius: 4px;
  width: 100%;
  padding: 16px 24px;
  &.shadow {
    box-shadow: 0px 3px 2px 0px #00000033;
  }
}
.box-layout {
  background: #17181a;
  box-shadow: 0px 4px 6px 0px #0000003d;
  border-radius: 16px;
  color: #fff;
  .box-contents {
    width: 100%;
    height: calc(100% - 52px);
    padding: 16px;
  }
  .box-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 19px 0 24px;
    border-bottom: 1px solid #2b3034;
    height: 52px;
    width: 100%;
    .title-wrap {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      justify-content: flex-start;
      em {
        color: #2c9aff;
        font-size: 38px;
      }
      p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 32px;
        font-weight: 400;
      }
    }
    .icon-icon_open_pop {
      padding: 5px;
      border-radius: 4px;
      color: #adb5bd !important;
      cursor: pointer;
      &:hover {
        background: rgba($color: #fff, $alpha: 0.16);
      }
      font-size: 32px;
    }
    .add_dashboard_icon {
      padding: 5px;
      border-radius: 4px;
      cursor: pointer;
      &:hover {
        background: rgba($color: #fff, $alpha: 0.16);
      }
    }
  }
}
.MuiButtonBase-root {
  padding: 0 !important;
}
.detail-contents {
  width: 100%;
  height: calc(100% - 52px);
}
.table-scroll-wrap {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-thumb {
    height: 30%;
    background: #545c64;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background: #212529;
  }
}
input[type="text"],
input[type="password"] {
  box-sizing: border-box;
}
//토스트
.toast-wrap {
  border-radius: 8px;
  padding: 16px 20px;
  min-width: 520px;
  width: 520px;
  min-height: 88px;
  height: 100%;
  border-radius: 6px;
  color: #000;
  word-break: keep-all;
  font-size: 15px;
  font-weight: 500;
  box-shadow: 0px 4px 6px 0px #0000003d;
  align-items: center;
  align-items: flex-start;
  &.status {
    color: #fff !important;
  }
  .icon-wrap {
    align-items: flex-start;
    width: 35px;
    height: 100%;
    justify-content: flex-start;
    margin-right: 6px;
    svg {
      margin-top: 2px;
    }
  }
  .text-wrap {
    height: 100%;
    justify-content: flex-start;
    font-size: 15px;
    font-weight: 300;
    width: 100%;
    word-break: break-all;
    align-items: flex-start;
    text-align: left;
    .title {
      text-align: left;
      word-break: keep-all;
      font-weight: 600;
      font-size: 20px;
    }
  }
}
