@font-face {
  font-family: "Pretendard";
  font-weight: 100;
  src: url("./Pretendard-Thin.woff") format("woff");
}
@font-face {
  font-family: "Pretendard";
  font-weight: 200;
  src: url("./Pretendard-ExtraLight.woff") format("woff");
}
@font-face {
  font-family: "Pretendard";
  font-weight: 300;
  src: url("./Pretendard-Light.woff") format("woff");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 400;
  src: url("./Pretendard-Regular.woff") format("woff");
}
@font-face {
  font-family: "Pretendard";
  font-weight: 500;
  src: url("./Pretendard-Medium.woff") format("woff");
}
@font-face {
  font-family: "Pretendard";
  font-weight: 600;
  src: url("./Pretendard-SemiBold.woff") format("woff");
}
@font-face {
  font-family: "Pretendard";
  font-weight: 700;
  src: url("./Pretendard-Bold.woff") format("woff");
}
@font-face {
  font-family: "Pretendard";
  font-weight: 800;
  src: url("./Pretendard-ExtraBold.woff") format("woff");
}
@font-face {
  font-family: "Pretendard";
  font-weight: 900;
  src: url("./Pretendard-Black.woff") format("woff");
}
