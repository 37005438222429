.mymec-service-wrap {
  width: 100%;
  height: 100%;
  gap: 16px;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  align-items: center;
  .wrap-title {
    font-weight: 500;
    font-size: 14px;
  }
  .app-mgmt-header {
    justify-content: space-between;
    .info {
      justify-content: space-between;
      border: 2px solid #545c64;
      border-radius: 8px;
      padding: 0 24px;
      color: #e9ecef;
      height: 58px;
      gap: 30px;
      .title {
        font-size: 28px;
        font-weight: 500;
        span {
          display: inline-block;
          width: 20px;
          height: 20px;
          border-radius: 10px;
          margin-right: 10px;
          background: #adb5bd;
        }
      }
      .value {
        font-size: 48px;
        font-weight: 700;
      }
    }
    .left-wrap {
      gap: 16px;
    }
    .right-wrap {
      gap: 16px;
    }
  }
  .table-scroll-wrap {
    height: auto;
    overflow-y: visible;
  }
}
