.modal-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba($color: #000000, $alpha: 0);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 998;
  .modal-box {
    box-shadow: 0px 0px 24px 0px #000000cc;
    border: 1px solid #495057;
    background: #2b3034;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 6px;
    justify-content: space-between;
    max-height: 100%;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 2px;
    }
    &::-webkit-scrollbar-thumb {
      height: 30%;
      background: #545c64;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      background: #212529;
    }
    .modal-tab-btn-wrap {
      gap: 7px;
      .modal-tab-btn {
        padding: 8px 20px;
        font-size: 20px;
      }
    }
    //작은 모달
    .sm-dialog-header {
      min-width: 356px;
      width: 100%;
      display: flex;
      padding: 0 20px;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      height: 45px;
      min-height: 45px;
      color: #ced4da;
      font-weight: 600;
      font-size: 28px;
      .Y {
        color: #2c9aff;
      }
      .N {
        color: #f06262;
      }
      svg {
        margin-right: 6px;
      }
    }
    .sm-dialog-contents {
      width: 100%;
      height: 100%;
      padding: 10px 45px 10px 45px;
      p {
        text-align: left;
        font-size: 23px;
        font-weight: 500;
        color: #ced4da;
      }
    }
    .sm-dialog-btn-wrap {
      width: 100%;
      height: 54px;
      min-height: 54px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 20px;
      gap: 12px;
      .btn {
        padding: 7px 17px;
        box-shadow: 0px 2px 1px 0px #00000029;
        border: 1px solid #545c64;
        border-radius: 4px;
        justify-self: flex-start;
        font-size: 26px;
        font-weight: 500;
        color: #adb5bd;
        &.submit-btn {
          background: #495057;
          color: #fff;
          &.error {
            background: #2b3034;
            color: #545c64;
            border: none;
          }
        }
      }
    }
    //다이얼로그
    .dialog-header {
      min-width: 356px;
      width: 100%;
      display: flex;
      padding: 0 20px;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      height: 60px;
      min-height: 60px;
      color: #ced4da;
      border-bottom: 1px solid #495057;
      font-weight: 600;
      font-size: 14px;
      .Y {
        color: #2c9aff;
      }
      .N {
        color: #f06262;
      }
      svg {
        margin-right: 6px;
      }
    }
    .dialog-contents {
      width: 100%;
      height: 100%;
      padding: 15px 30px;
      p {
        font-size: 13px;
        font-weight: 400;
        color: #ced4da;
      }
    }
    .dialog-btn-wrap {
      width: 100%;
      height: 54px;
      min-height: 54px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 20px;
      gap: 12px;
      .btn {
        padding: 10px 20px;
        box-shadow: 0px 2px 1px 0px #00000029;
        border: 1px solid #545c64;
        border-radius: 4px;
        justify-self: flex-start;
        font-size: 13px;
        font-weight: 500;
        color: #adb5bd;
        &.submit-btn {
          background: #495057;
          color: #fff;
          &.error {
            background: #2b3034;
            color: #545c64;
            border: none;
          }
        }
      }
    }
    //모달
    .modal-header {
      width: 100%;
      display: flex;
      padding: 0 20px;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      height: 60px;
      min-height: 60px;
      color: #ced4da;
      border-bottom: 1px solid #495057;
      .title {
        font-size: 32px;
        font-weight: 700;
      }
    }
    .modal-btn-wrap {
      width: 100%;
      height: 70px;
      min-height: 70px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 30px;
      background: #212529;
      gap: 12px;
      &.update-user {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .flexRow {
          gap: 12px;
        }
      }
      .btn {
        padding: 10px 20px;
        box-shadow: 0px 2px 1px 0px #00000029;
        border: 1px solid #545c64;
        border-radius: 4px;
        justify-self: flex-start;
        font-size: 28px;
        font-weight: 500;
        &.submit-btn {
          &.error {
            background: #2b3034;
            color: #545c64;
            border: none;
          }
        }
      }
    }
    .modal-contents {
      width: 100%;
      height: 100%;
      padding: 15px 30px;
      //파드, 디플로이, 서비스 추가
      .add-pod-wrap-wrap {
        width: 420px;
        height: 100%;
        .input-log-wrap {
          width: 100%;
          height: 100%;
          max-height: 456px;
          overflow-y: auto;
          &::-webkit-scrollbar {
            width: 2px;
          }
          &::-webkit-scrollbar-thumb {
            height: 30%;
            background: #545c64;
            border-radius: 10px;
          }
          &::-webkit-scrollbar-track {
            background: #17181a;
          }
        }
        & > .flexCol {
          margin-bottom: 25px;
        }
        .label {
          display: inline-block;
          align-self: flex-start;
          color: #adb5bd;
          font-size: 13px;
          font-weight: 500;
          margin: 6px 0;
          &.require {
            position: relative;
            &::after {
              content: "*";
              position: absolute;
              right: -10px;
              color: #f06262;
            }
          }
        }

        input[type="text"] {
          width: 420px;
          max-width: 420px;
          border: 1px solid #545c64;
          box-shadow: 0px 2px 1px 0px #00000029;
          background: inherit;
          height: 38px;
          border-radius: 4px;
          font-size: 14px;
          font-weight: 400;
          color: #fff;
          padding: 0 12px;
          &.huge {
            height: 100px;
          }
          p &::placeholder {
            color: #868e96;
            font-size: 14px;
            font-weight: 400;
          }
          &.error {
            border: 1px solid #f06262 !important;
            color: #f06262 !important;
          }
        }
      }
      //우선순위 설정
      .drag-priority {
        width: 100%;
        height: 100%;
        max-height: 545px;
        overflow-y: auto;
        min-height: 110px;
        &::-webkit-scrollbar {
          width: 2px;
        }
        &::-webkit-scrollbar-thumb {
          height: 30%;
          background: #545c64;
          border-radius: 10px;
        }
        &::-webkit-scrollbar-track {
          background: #17181a;
        }
        .priority-item {
          width: 100%;
          gap: 4px;
          padding: 3px 0;
          &:hover {
            background: #0e7ade33;
          }
          .box {
            border: 1px solid #545c64;
            box-shadow: 0px 2px 1px 0px #00000029;
            background: #212529;
            height: 38px;
            border-radius: 4px;
            line-height: 38px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 0 10px;
            text-align: left;
            font-size: 18px;
            font-weight: 400;
            color: #adb5bd;
          }
          .num {
            width: 28px;
            font-size: 16px;
            font-weight: 600;
            color: #adb5bd;
          }
          .name {
            width: 100px;
          }
          .key {
            width: 288px;
          }
          .icon-icon_drag_handle {
            color: #adb5bd;
            width: 28px;
          }
        }
      }
      //서비스 정보
      .service-info-wrap {
        width: 600px;
        height: 100%;
        & > .flexCol {
          margin-bottom: 25px;
        }
        .label {
          display: inline-block;
          align-self: flex-start;
          color: #adb5bd;
          font-size: 26px;
          font-weight: 500;
          margin: 6px 0;
          &.require {
            position: relative;
            &::after {
              content: "*";
              position: absolute;
              right: -10px;
              color: #f06262;
            }
          }
        }

        input[type="text"] {
          width: 600px;
          max-width: 600px;
          border: 1px solid #545c64;
          box-shadow: 0px 2px 1px 0px #00000029;
          background: inherit;
          height: 38px;
          border-radius: 4px;
          font-size: 28px;
          font-weight: 400;
          color: #fff;
          padding: 0 12px;
          &.huge {
            height: 100px;
          }
          p &::placeholder {
            color: #868e96;
            font-size: 14px;
            font-weight: 400;
          }
          &.error {
            border: 1px solid #f06262 !important;
            color: #f06262 !important;
          }
        }
        textarea {
          width: 600px;
          max-width: 600px;
          border: 1px solid #545c64;
          box-shadow: 0px 2px 1px 0px #00000029;
          background: inherit;
          height: 38px;
          border-radius: 4px;
          font-size: 28px;
          font-weight: 400;
          color: #fff;
          padding: 10px 12px;
          &.huge {
            max-height: 100px;
            height: 100px;
            overflow-y: auto;
            &::-webkit-scrollbar {
              width: 2px;
            }
            &::-webkit-scrollbar-thumb {
              height: 30%;
              background: #545c64;
              border-radius: 10px;
            }
            &::-webkit-scrollbar-track {
              background: #17181a;
            }
          }
          p &::placeholder {
            color: #868e96;
            font-size: 14px;
            font-weight: 400;
          }
          &.error {
            border: 1px solid #f06262 !important;
            color: #f06262 !important;
          }
        }
      }
      //서비스 생성
      .add-service-wrap {
        width: 600px;
        height: 100%;
        & > .flexCol {
          margin-bottom: 25px;
        }
        .label {
          display: inline-block;
          align-self: flex-start;
          color: #adb5bd;
          font-size: 26px;
          font-weight: 500;
          margin: 6px 0;
          &.require {
            position: relative;
            &::after {
              content: "*";
              position: absolute;
              right: -10px;
              color: #f06262;
            }
          }
        }

        input[type="text"] {
          width: 600px;
          max-width: 600px;
          border: 1px solid #545c64;
          box-shadow: 0px 2px 1px 0px #00000029;
          background: #212529;
          height: 38px;
          border-radius: 4px;
          font-size: 24px;
          font-weight: 400;
          color: #fff;
          padding: 0 12px;
          &.huge {
            height: 100px;
          }
          p &::placeholder {
            color: #868e96;
            font-size: 24px;
            font-weight: 400;
          }
          &.error {
            border: 1px solid #f06262 !important;
            color: #f06262 !important;
          }
        }
        textarea {
          width: 600px;
          max-width: 600px;
          border: 1px solid #545c64;
          box-shadow: 0px 2px 1px 0px #00000029;
          background: #212529;
          height: 38px;
          border-radius: 4px;
          font-size: 24px;
          font-weight: 400;
          color: #fff;
          padding: 10px 12px;
          &.huge {
            height: 100px;
          }
          p &::placeholder {
            color: #868e96;
            font-size: 24px;
            font-weight: 400;
          }
          &.error {
            border: 1px solid #f06262 !important;
            color: #f06262 !important;
          }
        }
      }
      //회원가입
      .signup-contents {
        .signup-grid {
          justify-content: space-between;
          gap: 16px;
          width: 100%;
          margin-bottom: 6px;
        }
        .alone-grid {
          width: 100%;
          margin-bottom: 6px;
          input[type="text"] {
            width: 100%;
            max-width: inherit;
          }
        }
        &.is-edit {
          input[type="text"],
          input[type="password"] {
            border: 1px solid #545c64 !important;
            background: #212529 !important;
            color: #adb5bd !important;
            &:disabled {
              background: #2b3034 !important;
              color: #545c64 !important;
            }
          }
        }
        &.no-edit {
          input[type="text"],
          input[type="password"] {
            color: #adb5bd !important;
            pointer-events: none !important;
          }
        }
      }
      .post-btn-wrap {
        width: 300px;
        height: 65px;
        align-items: flex-start;
        justify-content: flex-end;
        button {
          width: 117px;
          max-width: 117px;
          box-shadow: 0px 2px 1px 0px #00000029;
          border: 1px solid #545c64;
          border-radius: 4px;
          height: 38px;
          justify-self: flex-start;
          font-size: 18px;
          font-weight: 500;
          color: #adb5bd;
        }
      }
      .signup-input-wrap {
        align-items: flex-start;

        .password-wrap {
          width: 100%;
          position: relative;
          display: flex;
          align-items: center;
          svg {
            z-index: 14;
            right: 10px;
            position: absolute;
          }
        }
        .label {
          color: #adb5bd;
          font-size: 20px;
          font-weight: 500;
          margin: 6px 0;
          &.require {
            position: relative;
            &::after {
              content: "*";
              position: absolute;
              right: -15px;
              color: #f06262;
            }
          }
        }
        input[type="text"],
        input[type="password"] {
          width: 300px;
          max-width: 300px;
          border: 1px solid #545c64;
          box-shadow: 0px 2px 1px 0px #00000029;
          background: #2b3034;
          height: 38px;
          border-radius: 4px;
          font-size: 28px;
          font-weight: 400;
          color: #fff;
          padding: 0 12px;

          p &::placeholder {
            color: #868e96;
            font-size: 28px;
            font-weight: 400;
          }
          &.error {
            border: 1px solid #f06262 !important;
            color: #f06262 !important;
          }
        }
        .input-msg {
          margin-top: 6px;
          width: 100%;
          height: 14px;
          text-align: left;
          font-size: 13px;
          font-weight: 400;
          color: #f06262;
        }
      }

      //앱관리 > 상세보기
      .tab-wrapper {
        width: 100%;
        margin-bottom: 10px;
        justify-content: space-between;
        .MuiTabs-flexContainer {
          .MuiTab-root {
            font-size: 28px;
            font-weight: 600;
            color: #868e96;
            min-width: inherit !important;
            padding: 0 10px !important;
            font-family: "Pretendard", sans-serif !important;
            &.Mui-selected {
              color: #2c9aff !important;
            }
          }
        }
      }
      .panel-wrapper {
        & > div {
          padding: 0 !important;
          display: flex;
          align-items: center;
          gap: 24px;
          justify-content: space-between;
          & > div {
            width: 500px;
            height: 440px;
          }
        }
        .left-sec {
          .custom-table-wrap {
            height: 100%;
          }
        }
        .right-sec {
          overflow-y: auto;
          &::-webkit-scrollbar {
            width: 3px;
          }
          &::-webkit-scrollbar-thumb {
            height: 30%;
            background: #545c64;
            border-radius: 10px;
          }
          &::-webkit-scrollbar-track {
            background: #212529;
          }
        }
      }
      //서비스 > 상세보기
      .condition-text {
        color: #ced4da;
        font-size: 26px;
        font-weight: 400;
        align-self: flex-start;
        text-align: left;
        margin-bottom: 8px;
      }
      .desc-sec-wrap {
        max-width: 1664px;
        .desc-sec2 {
          font-size: 26px;
          font-weight: 400;
          color: #ced4da;
          width: 100%;
          border: 1px solid #343a40;
          background: #212529;
          padding: 8px 16px;
          border-radius: 4px;
          text-align: left;
        }
        .edit-btn {
          width: 45px;
          cursor: pointer;
        }
      }
      .desc-sec {
        font-size: 26px;
        font-weight: 400;
        max-width: 1664px;
        color: #ced4da;
        border: 1px solid #343a40;
        background: #212529;
        padding: 8px 16px;
        border-radius: 4px;
        text-align: left;
      }
      .panel-wrapper {
        & > div {
          padding: 0 !important;
          display: flex;
          align-items: center;
          gap: 24px;
          justify-content: space-between;
          & > div { //팝업 박스 크기(패널 내 객체들은 별도 수정 필요)
            width: 720px;
            min-height: 440px;
          }
        }
        .left-sec2 {
          .custom-table-wrap {
            height: 100%;
          }
        }
        .right-sec2 {
          width: 720px;
          max-height: 440px;
          overflow-y: auto;
          .data-table-viewer {
            padding: 8px 12px;
            width: 100%;
            min-height:440px;
            border: 1px solid #343a40;
            background: #212529;
            table {
              tr {
                td {
                  border-bottom: 1px solid #343a40;
                  font-size: 21px;
                  font-weight: 400;
                  width: 100%;
                  color: #ced4da;
                  .black-box {
                    width: 100%;
                    padding: 8px 12px;
                    background: #00000033;
                    border-radius: 2px;
                    margin-bottom: 2px;
                    .black-box-line {
                      width: 100%;
                      height: 100%;
                      border-bottom: 1px solid #343a40;
                      span.key {
                        text-align: left;
                        width: 35%;
                        line-break: anywhere;
                      }
                      span.value {
                        text-align: left;
                        width: 65%;
                      }
                    }
                  }
                  p {
                    text-align: left;
                    min-height: 33px;
                    height: 100%;
                    &.center {
                      display: flex;
                      align-items: center;
                    }
                  }
                  &.table-key {
                    font-weight: 500;
                    color: #868e96;
                    width: 100px;
                    max-width: 100px;
                    min-width: 100px;
                  }
                }
              }
            }
          }
          &::-webkit-scrollbar {
            width: 3px;
          }
          &::-webkit-scrollbar-thumb {
            height: 30%;
            background: #545c64;
            border-radius: 10px;
          }
          &::-webkit-scrollbar-track {
            background: #212529;
          }
        }
      }
    }
  }
}
