.dash-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  .left-sec {
    height: 100%;
    width: 63%;
    gap: 24px;
    .mec-app-wrap {
      width: 100%;
      height: 180px;
      .box-contents {
        padding: 16px 30px;
        .mec-item {
          width: 20%;
          height: 100%;
          align-items: flex-start;
          justify-content: center;
          border-left: 1px solid #343a40;
          padding-left: 13px;
          gap: 7px;
          &:first-child {
            border: none;
            padding-left: 0;
          }
          em {
            padding: 1px;
            font-size: 26px;
          }
          .mec-item-info {
            .mec-item-label {
              height: 28px;
              line-height: 28px;
              color: #868e96;
              font-size: 28px;
              font-weight: 500;
              text-align: center;
            }
            .mec-item-value {
              font-size: 48px;
              font-weight: 700;
              text-align: center;
            }
          }
        }
      }
    }
    .cluster-wrap {
      width: 100%;
      height: 100%;
      .box-contents {
        .cluster-info {
          width: calc(100% - 417px);
          height: 100%;
          border-left: 1px solid #2b3034;
          padding: 10px 0px 10px 15px;

          .cluster-name-wrap {
            background: #495057;
            border-radius: 8px;
            padding: 11px 17px;
            margin-bottom: 15px;
            min-height: 60px;
            &:not(.disabled) {
              cursor: pointer;
              &:hover {
                background: rgba($color: #fff, $alpha: 0.3);
              }
            }

            em {
              align-self: flex-start;
              margin-right: 10px;
              font-size: 32px;
            }
            .flexCol {
              justify-content: flex-start;
              align-items: flex-start;
              .cluster-name {
                font-size: 32px;
                font-weight: 700;
              }
              .cluster-address {
                margin-top: 5px;
                font-size: 26px;
                font-weight: 500;
              }
            }
          }
          .cluster-other-chart {
            margin-top: 15px;
            width: 100%;
            height: 28.5%;
            overflow: hidden;
            .cluster-bandwidth {
              width: 50%;
              height: 100%;
            }
            .cluster-packet {
              width: 50%;
              height: 100%;
            }
            .cluster-bandpacket {
              width: 100%;
              height: 100%;
            }
          }
          .cluster-disk-io {
            width: 100%;
            height: 28.5%;
            margin-top: 15px;
            overflow: hidden;
          }
          .cluster-usage-wrap {
            padding: 5px 0;
            zoom: 0.8;
            height: 25%;
            overflow: hidden;
            justify-content: space-around;
            border-bottom: 1px solid #495057;
          }
        }
      }
    }
  }
  .right-sec {
    height: 100%;
    width: 37%;
    gap: 24px;
    .mec-service-wrap {
      width: 100%;
      height: 55%;
      .box-contents {
        display: flex;
        flex-direction: column;
        .service-info-wrap {
          width: 100%;
          .mec-item-info {
            width: 33.333333%;
            .mec-item-label {
              height: 28px;
              line-height: 28px;
              color: #868e96;
              font-size: 28px;
              font-weight: 500;
              text-align: center;
            }
            .mec-item-value {
              font-size: 48px;
              font-weight: 700;
              text-align: center;
            }
          }
        }
        .line {
          margin: 24px 0;
        }
        .top5-service-wrap {
          height: 100%;
          .title {
            font-size: 28px;
            font-weight: 500;
            width: 100%;
            text-align: left;
            margin-bottom: 15px;
          }
          .mec-bar-wrap {
            width: 100%;
            gap: 10px;
            height: calc(100% - 52px);
            justify-content: space-between;
            .mec-bar-item {
              width: 100%;
              height: 100%;
              justify-content: space-between;
              .bar {
                padding-left: 17px;
                text-align: left;
                width: 90%;
                border-radius: 1000px;
                height: 95%;
                background: #2b3034;
                overflow: hidden;
                position: relative;
                .fill {
                  position: absolute;
                  height: 100%;
                  left: 0;
                  top: 0;
                  z-index: 98;
                  background: #2c9aff;
                }
                p {
                  z-index: 99;
                  font-size: 24px;
                  font-weight: 500;
                }
              }
              p {
                font-size: 24px;
                font-weight: 500;
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
    .status-wrap {
      width: 100%;
      height: 45%;
      gap: 24px;
      .flex-col-item {
        width: 100%;
        padding: 12px;
        border-bottom: 1px solid #495057;
        .item-title {
          align-self: center;
          font-weight: 500;
          font-size: 28px;
          color: #a4a4a4;
          // margin-bottom: 15px;
        }
        .item-value {
          align-self: center;
          font-weight: 700;
          font-size: 48px;
          color: #fff;
        }
      }
      .user-status-wrap {
        width: 50%;
        height: 100%;
        .box-contents {
        }
      }
      .orchestration-wrap {
        width: 50%;
        height: 100%;
        .box-contents {
        }
      }
    }
  }
}
