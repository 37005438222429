.ReactCodeMirror {
  // width: 420px !important;
  height: 456px !important;
  max-height: 456px !important;
  border: 1px solid #343a40 !important;
  background: #191c1fb2 !important;
  background-color: #191c1fb2 !important;
  .CodeMirror {
    width: 100% !important;
    height: 100% !important;
    text-align: left !important;
    background: #191c1fb2 !important;
    background-color: #191c1fb2 !important;
    font-size: 20px !important;
    font-weight: 400 !important;
    color: #fff !important;
    font-family: "Courier";
    .CodeMirror-vscrollbar {
      &::-webkit-scrollbar {
        width: 2px !important;
      }
      &::-webkit-scrollbar-thumb {
        height: 30% !important;
        background: #545c64 !important;
        border-radius: 10px !important;
      }
      &::-webkit-scrollbar-track {
        background: #17181a !important;
      }
    }
  }
}
.CodeMirror-lines {
  padding: 0 !important;
}
.CodeMirror-gutter {
  background: #343a40 !important;
  background-color: #343a40 !important;
}
.CodeMirror-linenumber {
  background: #343a40 !important;
  background-color: #343a40 !important;
  font-size: 20px !important;
  font-weight: 400 !important;
  color: #868e96 !important;
  text-align: left !important;
}
