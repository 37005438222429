.detail-header {
  background: linear-gradient(102.77deg, #3c7ddf 10.85%, #3351ec 92.37%);
  width: 100%;
  height: 52px;
  min-height: 52px;
  border-radius: 4px;
  padding: 0 12px;
  color: #fff;
  justify-content: space-between;
  .select-wrap {
    height: 32px;
    gap: 8px;
    .custom-select-wrap {
      box-shadow: none;
    }
    .custom-select-option {
      top: 33px;
    }
    .left-select-wrap {
      height: 100%;
      width: 100px;
    }
    .right-select-wrap {
      width: 200px;
      height: 100%;
    }
  }
  .detail-info {
    height: 100%;
  }
  .MuiButtonBase-root {
    margin-right: 12px;
  }
  .ver-line {
    width: 1px;
    height: 28px;
    background: #fff;
    margin: 0 15px;
  }
  .detail-name {
    font-size: 40px;
    font-weight: 700;
  }
  .detail-sub-name {
    font-size: 28px;
    font-weight: 500;
  }
  .empty {
    width: 20px;
    height: 20px;
    margin-right: 12px;
  }
}
.add-btn {
  width: 72px;
  height: 32px;
  font-size: 20px;
}
