.login-page {
  padding: 0;
  margin: 0 50px;
  width: auto !important;
  height: calc(100vh - 250px) !important;
  min-height: 700px;
  background: white !important;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  .banner-area {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }
  .login-box-wrap {
    width: 500px;
    height: 100%;
    padding: 22px;
    margin-top: 4rem;
    .main-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 2px 1px 0 #00000029;
      height: 48px;
      line-height: 33px;
      font-weight: 500;
      &:not(.signup) {
        border-radius: 16px;
        background: #233e94;
        margin-top: 3rem;
        font-size: 1.5rem;
      }
      &:last-child {
        margin-top: 12px;
      }
      &.signup {
        background: none;
        box-shadow: none;
        color: #797d80;
        font-size: 16px;
      }
      &.disabled {
        background: rgba(0, 0, 0, 0.12);
        color: rgba(0, 0, 0, 0.26);
        pointer-events: none;
      }
    }
    .login-box {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .login-input-wrap {
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        height: 100%;
        .logo {
          display: flex;
          justify-content: center;
          color: black;
          font-weight: bold;
          font-size: 48px;
          margin-bottom: 4rem;
          .logo-giga {
            color: #32b8c4;
          }
          .logo-mec {
            color: #233e94;
          }
        }
        label {
          display: inline-block;
          width: 100%;
          margin-bottom: 30px;
          p {
            text-align: left;
            font-size: 22px;
            font-weight: 600;
            color: #233e94;
            margin-bottom: 0.5rem;
            span {
              padding-left: 8px;
            }
          }
          input {
            box-sizing: border-box;
            width: 100%;
            padding: 0 12px;
            border: none;
            border-bottom: 2px solid gray;
            height: 38px;
            font-size: 25px;
            font-weight: 400;
            color: #222222 !important;
            &.error {
              border: 1px solid #f06262 !important;
              color: #f06262 !important;
            }
          }
          .error-msg {
            margin-top: 4px;
            width: 100%;
            height: 14px;
            min-height: 14px;
            max-height: 14px;
            p {
              color: #f06262;
              font-weight: 400 !important;
              font-size: 13px !important;
              margin-bottom: 0 !important;
            }
          }
          .password-wrap {
            width: 100%;
            position: relative;
            display: flex;
            align-items: center;
            svg {
              z-index: 14;
              right: 10px;
              position: absolute;
            }
          }
        }
      }
    }
  }
}
