.bannerContainer {
  height: 100%;
  width: calc(100% - 50px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.loginBanner {
  width: 100%;
  height: calc(100vh - 390px);
  border-radius: 16px;
  object-fit: contain; // 이미지 비율 유지하며 컨테이너에 맞춤
}

.dotWrap {
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #d3d3d3;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dot.current {
  background-color: #333;
}
