.vim-wrap {
  width: 100%;
  height: 100%;
  gap: 16px;
  justify-content: flex-start;
  .contents-wrap {
    width: 100%;
    align-items: flex-start;
    height: 100%;
    gap: 16px;
    .left-sec {
      height: 100%;
      width: 700px;
      min-width: 700px;
      gap: 24px;
      justify-content: space-between;
      .vim-chart-wrap {
        height: 20%;
        width: 100%;
        align-items: flex-start;
        justify-content: space-between;
        .vim-info-wrap {
          width: 280px;
          height: 100%;
          align-items: flex-start;
          .info-title {
            font-weight: 500;
            font-size: 19px;
            margin-bottom: 5px;
          }
          .vim-info-list {
            width: 100%;
            height: 100%;
            .list-item {
              justify-content: space-between;
              border: 1px solid #ffffff2e;
              border-radius: 4px;
              width: 100%;
              padding: 3px 15px;
              margin-bottom: 2px;

              p {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 16px;
                font-weight: 500;
              }
              .item-value,
              p > span {
                font-size: 19px !important;
                font-weight: 700 !important;
              }
              .item-value {
                text-align: right;
                min-width: 65px;
              }
            }
          }
        }
        .vim-info-chart {
          width: 300px;
          min-height: 182px;
          height: 100%;
        }
      }
    }
    .right-sec {
      border: none;
      padding: 0 !important;
    }
  }
}
