.orchestrationvim-wrap {
    width: 100%;
    height: 100%;
    gap: 16px;
    justify-content: flex-start;
    .tab-wrapper {
      margin: 10px 0 1px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .MuiTabs-flexContainer {
        .MuiTab-root {
          font-size: 28px;
          font-weight: 600;
          color: #868e96;
          min-width: inherit !important;
          padding: 0 10px !important;
          font-family: "Pretendard", sans-serif !important;
          &.Mui-selected {
            color: #2c9aff !important;
          }
        }
      }
    }
  }