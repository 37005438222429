.login-wrap {
  background: #dddfe8 !important;
  height: 100vh;
  .logo-area {
    margin: 50px 50px 30px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
