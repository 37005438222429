@import url("./assets/fonts/fonts.scss");
@import url("./assets/icons/icon.font/iconly.css");
@import url("./components/layout/Buttons.scss");
html,
body {
  margin: 0 auto;
  padding: 0;
  text-align: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: "Pretendard", sans-serif !important;
  position: relative;
  color: #fff;
  background: #17181a;
}

html {
  background: #f2f2f2;
  font-family: "Pretendard", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
input,
button,
textarea {
  font-family: "Pretendard", sans-serif !important;
}

a {
  text-decoration: none;
  color: #fff;
}
#root {
  height: 100%;
}
div {
  box-sizing: border-box;
}
ul {
  margin: 0 auto;
  padding: 0;
  text-decoration: none;
}
p,
span {
  margin: 0;
  padding: 0;
}
span {
  margin: 0;
  padding: 0;
  &.require {
    position: relative;
    &::after {
      content: "*";
      position: absolute;
      right: -10px;
      color: #f06262;
    }
  }
}
li {
  text-decoration: none;
}
.flexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.flexCol {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.verline {
  display: inline-block;
  width: 1px;
  height: 15px;
  background: #525a61;
  margin: 0px 15px;
}
.App {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.page {
  padding: 20px;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  background: #17181a;
}
.page::-webkit-scrollbar {
  width: 8px;
}
.page::-webkit-scrollbar-thumb {
  height: 30%;
  background: #bbbcbd;
  border-radius: 10px;
}
.page::-webkit-scrollbar-track {
  background: #f4f4f4;
}
.page-inner {
  width: 100%;
  height: 100%;
  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-thumb {
    height: 30%;
    background: #545c64;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background: #17181a;
  }
}
input,
textarea {
  &:focus {
    outline: none;
  }
}
textarea {
  resize: none;
  box-sizing: border-box;
}
.main-btn {
  height: 36px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  word-break: keep-all;
  color: #fff;
  background: #5e9eff;
  border-radius: 4px;
  padding: 5px 16px;
  cursor: pointer;
}
.main-tit {
}
select {
  border: 1px solid #dce1e5;
  background: #fff;
  border-radius: 4px;
  &:focus {
    outline: none;
  }
}

.table-circle {
  svg {
    margin-right: 5px;
  }
}
.active-input {
  border: 1px solid #5e9eff !important;
  box-shadow: 0px 0px 6px 0px #5e64ff66 !important;
  background: #f7faff !important;
}
.wd100 {
  width: 100% !important;
  max-width: 100% !important;
}
button {
  background: none;
  border: none;
  cursor: pointer;
}
.line {
  width: 100%;
  height: 1px;
  background: #495057;
}
